import React, { useState, useEffect } from "react";
import Ractangle from "./Ractangle";
// import image from "../images and logos/consulting.svg";
import Heading from "./shared/Heading";

import CloudMigtation from "../images and logos/logos/cloudMigration.svg";
import CloudSecurity from "../images and logos/logos/cloud security.svg";
import CloudApplicationDevelopment from "../images and logos/logos/application development.svg";
import CloudDataAnalytics from "../images and logos/logos/data analysis.svg";
import DevOps from "../images and logos/logos/devops.svg";
import grant from "../images and logos/grant2.jpeg";
import grant4 from "../images and logos/grant5.png";
import grant6 from "../images and logos/grant6.jpg";
import grant7 from "../images and logos/grant7.jpg";
import grant8 from "../images and logos/logo.png";
import grant9 from "../images and logos/consulting.webp";
import ModalForm from '../components/patners/ModalForm2';



import CloudManagement from "../images and logos/logos/cloud management.svg";
import CloudConsultaion from "../images and logos/logos/cloud consulttion.svg";
import { ArrowRight } from 'lucide-react';

// import { Link } from "react-router-dom";

const GrantWriting = () => {
  // const logos = [CloudConsultaion,CloudSecurity,CloudApplicationDevelopment, CloudDataAnalytics, DevOps, CloudManagement]
  const cards = [
    {
      logo: CloudMigtation,
      name: "Cloud Migration",
      description:
        "Transform your organization into an agile and scalable enterprise with our business cloud migration solutions...",
      link: "/cloud-migration",
    },
    {
        logo: CloudConsultaion,
        name: "Cloud Consultaion",
        description:
            "Cloud Migration Transform your organization into an agile and scalable enterprise with our business cloud migration...",
        link: "/cloud-consulting",
    },
    {
      logo: CloudSecurity,
      name: "Cloud Security",
      description:
        "Our threat-centric cloud security transformation services will help you lower your cyber risk while safeguarding...",
      link: "/cloud-security",
    },
    {
      logo: CloudApplicationDevelopment,
      name: "Cloud Application Development",
      description:
        "Continue to develop by using integrated delivery methods and scalable hosting for your cloud applications...",
      link: "/application-development",
    },
    {
      logo: CloudDataAnalytics,
      name: "Cloud and Data Analysis",
      description:
        "Every business is supported by data, a priceless resource that should be used to its full potential to advance the company...",
      link: "/cloud-and-data-analysis",
    },
    {
      logo: DevOps,
      name: "DevOps",
      description:
        "Become self-sustainable with your cloud initiatives through our subscription-based, agile DevOps-as-a-Service...",
      link: "/devops",
    },
    {
      logo: CloudManagement,
      name: "Cloud Management",
      description:
        "Governance and Management of the Cloud Utilize our professional cloud management solutions to modernize while concentrating....",
      link: "/cloud-management",
    },
  ];
  
  const Slide = ({ children, className = '' }) => (
    <div className={`flex flex-wrap ${className}`}>
      {children}
    </div>
  );
  
  const Section = ({ children, className = '' }) => (
    <div className={`w-full md:w-1/2 p-4 mb-10 ${className}`}>
      {children}
    </div>
  );
  

    return (
    <>
    <Ractangle text={"Solutions"} />
    <div className="dotted-2 lg:flex  md:flex sm:flex lg:flex-row md:flex-col-reverse sm:flex-col-reverse justify-between lg:px-[8%] pt-[60px] bg-[#F9F9F9]">
      <div className="lg:ml-0 sm:ml-0 md:ml-5">
      <Heading className="" black={"Grant"} blue={"Writing"} />

      <div className="container mx-auto">
        <Slide>
          <Section>
            <h1 className="text-2xl font-bold mb-4">Grant Writing Services for Nonprofit Technology Solutions</h1> 
            <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-cover "
                src={grant4}
            />
            {/* <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-contain"
                src={grant}
            /> */}
            <p>Mayhem Shield partners with nonprofits to secure grant funding for critical technology upgrades. We are experts in helping nonprofits leverage cloud technology, cybersecurity, and digital infrastructure.</p>
            {/* <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-cover "
                src={grant4}
            /> */}
          </Section>
          <Section>
        
            <h2 className="text-xl font-bold mb-4 lg:mt-10">Grant Writing Services: Your Path to Funding</h2>
            <br/>
 
            <ol className="list-decimal pl-5">
              <li>
                <h3 className="font-bold">Prospect Research</h3>
                <p>We identify grants that align with your technology and mission needs, ensuring you apply to the right opportunities.</p>
              </li>
              <br/>
              <li>
                <h3 className="font-bold">Grant Writing</h3>
                <p>We craft persuasive and fundable proposals, ensuring your nonprofit's needs are clearly communicated.</p>
              </li>
              <br/>
              <li>
                <h3 className="font-bold">Proposal Review</h3>
                <p>We meticulously review your proposals to ensure clarity, compliance, and alignment with grant priorities.</p>
              </li>
              <br/>
              <li>
                <h3 className="font-bold">Progress Reporting</h3>
                <p>We help you create compelling reports to demonstrate your success and compliance, maximizing future funding opportunities.</p>
              </li>
            </ol>
          </Section>
        </Slide>
  
        <Slide>
          <Section>
            <h2 className="text-xl font-bold mb-4">Technology Solutions for Nonprofits</h2>
            <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-contain"
                src={grant}
            />
            <h3 className="font-bold">Cloud Solutions</h3>
            <br/>
            <p>Enhance your service delivery and operational efficiency with secure, scalable cloud solutions.</p>
            <ul className="list-disc pl-5">
              <li>Streamlined data storage and access</li>
              <li>Improved collaboration and communication</li>
              <li>Cost savings and reduced infrastructure management</li>
            </ul>
          </Section>
          <Section>
            <h3 className="font-bold lg:mt-10">Cybersecurity</h3>
            <br/>
            <p>Protect your sensitive data and ensure the security of your systems with advanced cybersecurity solutions.</p>
            <ul className="list-disc pl-5">
              <li>Threat detection and prevention</li>
              <li>Data encryption and access control</li>
              <li>Regular security audits and vulnerability assessments</li>
            </ul>
            <br/>
            <h3 className="font-bold mt-4">Data Analytics</h3>
            <br/>
            <p>Gain valuable insights from your data to track impact, measure effectiveness, and make data-driven decisions.</p>
            <ul className="list-disc pl-5">
              <li>Program evaluation and performance tracking</li>
              <li>Donor engagement and retention analysis</li>
              <li>Resource allocation optimization</li>
            </ul>
          </Section>
        </Slide>
  
        <Slide>
          <Section>
            <h2 className="text-xl font-bold mb-4">Digital Platforms: Expanding Reach and Impact</h2>
            <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-contain"
                src={grant7}
            />
            <ol className="list-decimal pl-5">
              <li>
                <h3 className="font-bold mt-4">Remote Services</h3>
                <p>Offer virtual programs, counseling, and support services to reach a wider audience and expand your impact.</p>
              </li>
              <li>
                <h3 className="font-bold mt-4">Volunteer Management</h3>
                <p>Streamline volunteer recruitment, training, and scheduling, maximizing your volunteer capacity and engagement.</p>
              </li>
              <li>
                <h3 className="font-bold mt-4">Donor Engagement</h3>
                <p>Build meaningful relationships with your donors through customized communication, online donation platforms, and personalized experiences.</p>
              </li>
              <li>
                <h3 className="font-bold mt-4">Digital Marketing</h3>
                <p>Promote your mission and programs effectively through targeted online advertising, social media campaigns, and website optimization.</p>
              </li>
            </ol>
          </Section>
          <Section>
            <h2 className="text-xl font-bold mb-4">Our Expertise: Unlocking Technology for Nonprofits</h2>
            <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-contain"
                src={grant6}
            />
            <h3 className="font-bold">Cloud Computing Expertise</h3>
            <p>We leverage cloud technologies to enhance your operational efficiency, reduce costs, and expand your reach.</p>
            <h3 className="font-bold mt-4">Cybersecurity Solutions</h3>
            <p>We protect your sensitive data with comprehensive cybersecurity solutions, ensuring your nonprofit's security and privacy.</p>
            <h3 className="font-bold mt-4">Data Analytics and Insights</h3>
            <p>We help you leverage data to track your impact, measure success, and make data-driven decisions.</p>
            <h3 className="font-bold mt-4">Digital Platform Development</h3>
            <p>We design and develop digital platforms that enhance your service delivery, volunteer management, and donor engagement.</p>
          </Section>
        </Slide>

        <Slide>
        <Section>
            <h2 className="text-xl font-bold mb-4">Why Choose Mayhem Shield?</h2>
            <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-contain h-[200px]"
                src={grant9}
            />
            <h3 className="font-bold">Expertise and Experience</h3>
            <p>We have nearly 20 years of experience working with nonprofits, including 10+ years dedicated to grant writing.</p>
            <h3 className="font-bold mt-4">End-to-End Support</h3>
            <p>From identifying funding opportunities to implementing successful technology solutions, we are your trusted partner.</p>
            <h3 className="font-bold mt-4">Nonprofit-Focused Technology</h3>
            <p>Our technology solutions are designed to meet grant funding criteria and enhance your mission impact.</p>
            <h3 className="font-bold mt-4">Proven Track Record</h3>
            <p>We have a proven track record of helping nonprofits secure grant funding and implement impactful technology solutions.</p>
          </Section>

          <Section>
            <h2 className="text-xl font-bold mb-4">Contact Mayhem Shield Today!</h2>
            <img
                className="lg:w-full md:w-full sm:w-full mx-auto mb-5 lg:mt-10 md:mt-5 sm:mt-10 object-contain"
                src={grant8}
            />
            <p>Contact us today to discuss how Mayhem Shield can help your nonprofit secure funding and implement impactful technology solutions. We are committed to empowering nonprofits to achieve their missions and make a difference in the world.</p>
            <a href="/lets-talk" className=" mt-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
              CONTACT US   
            </a>
            <br/>
           
            <SubscriptionForm/>

          </Section>
        </Slide>
  
      </div>
      </div>
      </div>
    </>
    );
  };





export default GrantWriting;





function SubscriptionForm() {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');  // State to manage the email input value

  // Function to toggle modal
  const modalHandler = () => setShowModal(!showModal);

  // To control page scrolling when modal is open
  useEffect(() => {
    if (showModal) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = 'scroll';
  }, [showModal]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formData = new FormData(e.target); // Create FormData object from form
    fetch(
      'https://script.google.com/macros/s/AKfycbyEBIac-_5Ggxov9a6WYC1ph7FXY3UbAMUXVabM-5_EdWboafhzsOghl3HfqKd5ztxa/exec', // Replace with your script URL
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((res) => res.text()) // Change to text() to handle plain text response
      .then((data) => {
        console.log(data); // Success message handling
        setEmail(''); // Clear input field after submission
      })
      .catch((error) => {
        console.log(error); // Handle errors
      });
  };

  return (
    <>
      <ModalForm showModal={showModal} modalHandler={modalHandler} />

      <p
        onClick={modalHandler}
        className="mt-5 btn rounded px-5 py-3 bg-[#f99d34] border-0 text-center text-xl font-lg hover:bg-[#f99d34e9] text-white mt-5 drop-shadow-xl"
      >
        Sign Up For Grant Services
      </p>

      <div>
        <form className="form mt-4" onSubmit={handleSubmit}>
          <input
            placeholder=" To our newsletter ..."
            name="Email"
            type="email"
            required
            value={email}  // Bind input to state
            onChange={(e) => setEmail(e.target.value)}  // Update state on change
            className="text-white font-bold py-2 px-4 rounded inline-flex items-center"
          />
          <button
            type="submit"
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            Subscribe
          </button>
        </form>
      </div>
    </>
  );
}


